<template>
  <div class="editMaterial">
    <div style="margin-bottom: 30px;">
      <a-divider orientation="left">
        <h3>Topic & Sub-Topic</h3>
      </a-divider>
      <div class="textMaterial">
        <div class="content">
          <a-row :gutter="16">
            <a-col :xs="24" :lg="12">
              <a-form-item label="Topic">
                <a-textarea
                  v-model="materialModel.nama"
                  allow-clear
                  @change="onChange"
                  :autoSize="{ minRows: 8 }"
                />
                <!-- <a-input v-model="materialModel.nama" @change="onChange" style="height: 48px" /> -->
              </a-form-item>
            </a-col>
            <a-col :xs="24" :lg="12">
              <a-form-item label="Sub Topic">
                <a-textarea
                  v-model="materialModel.subTopik"
                  allow-clear
                  @change="onChange"
                  :autoSize="{ minRows: 8 }"
                />
                <!-- <a-input v-model="materialModel.subTopik" @change="onChange" style="height: 48px" /> -->
              </a-form-item>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
    <a-divider orientation="left">
      <h3>Video</h3>
    </a-divider>
    <div class="content">
      <div>
        <div v-for="(video, i) in materialModel.videos" :key="i">
          <a-row :gutter="[16, 16]">
            <a-col :xs="24" :lg="22">
              <span class="videoThumbnail">
                <iframe width="100%" height="400px" :src="video"></iframe>
              </span>
            </a-col>
            <!-- <a-col :xs="24" :lg="16">
              <h4>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus eveniet dolores laborum cumque natus laboriosam?</h4>
              <p>08:32</p>
            </a-col>-->
            <a-col :xs="24" :lg="2">
              <a-button
                @click.prevent="deleteVideo(i)"
                type="danger"
                icon="close"
                style="width:100%; height:48px;"
              ></a-button>
            </a-col>
          </a-row>
          <hr />
        </div>
      </div>
      <div>
        <a-row :gutter="[16, 16]">
          <a-col :xs="24" :lg="22">
            <a-input
              v-model="newVideo"
              placeholder="Paste youtube videos link (example: 'https://www.youtube.com/watch?v=WFIPwOPbPk0&t=3s')"
              style="height:48px;"
            />
          </a-col>
          <a-col :xs="24" :lg="2">
            <a-button
              :disabled="!newVideo"
              :loading="loadingAddVideo"
              @click.prevent="addVideo()"
              :type="!newVideo ? 'default' : 'primary'"
              icon="plus"
              style="width:100%; height:48px;"

            ></a-button>
          </a-col>
        </a-row>
      </div>
    </div>
    <a-divider orientation="left">
      <h3>Reading Material</h3>
    </a-divider>
    <div class="content">
      <ckeditor
        :editor="editor"
        v-model="materialModel.brief"
        @input="onChange"
        :config="editorConfig"
      ></ckeditor>
      <!-- {{materialModel.brief}} -->
      <!-- <wysiwyg v-model="materialModel.brief" @change="onChange" /> -->
    </div>
    <a-row type="flex" align="middle" justify="end">
      <a-col :span="24">
        <a-divider orientation="left">
          <h3>Attached Materials</h3>
        </a-divider>
      </a-col>
    </a-row>
    <div class="content">
      <div v-for="item in fileMaterials" :key="item.id">
        <a-row :gutter="[16,16]">
          <a-col :xs="24" :lg="20">
            <a-input :value="item.originalname" read-only style="height:48px;" />
          </a-col>
          <a-col :xs="24" :lg="2">
            <a-button
              @click="downloadFile(item.filepath, item.filename)"
              icon="download"
              style="width:100%; height:48px;"
            ></a-button>
          </a-col>
          <a-col :xs="24" :lg="2">
            <a-button
              @click.prevent="deleteFile(item.id, item.filepath, item.filename)"
              type="danger"
              icon="close"
              style="width:100%; height:48px;"
              :loading="loadingDelete"
            ></a-button>
          </a-col>
        </a-row>
        <hr />
      </div>
      <div>
        <a-row :gutter="[16, 16]">
          <form @submit.prevent="addFile" enctype="multipart/form-data">
            <a-col :xs="24" :lg="22">
              <a-input
                class="pt-4 pb-5"
                type="file"
                ref="file"
                placeholder="Upload additional documents or files for this session (https://www.youtube.com/watch?v=WFIPwOPbPk0)"
                style="height:48px;"
                @change="selectFile"
              />
              <small
                class="text-secondary mt-4"
              >You should upload file with the 'pdf' or 'docx' format</small>
            </a-col>
            <a-col :xs="24" :lg="2">
              <a-button
                :disabled="!newFile"
                :type="!newFile ? 'secondary' : 'primary'"
                @click.prevent="addFile()"
                icon="plus"
                style="width:100%; height:48px;"
                :loading="loadingAdd"
              ></a-button>
            </a-col>
          </form>
        </a-row>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import config from '@/config'
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
// import Font from '@ckeditor/ckeditor5-font/src/font'
export default {
  data() {
    return {
      config,
      loadingAddVideo: false,
      loadingDeleteVideo: false,
      loadingAdd: false,
      loadingDelete: false,
      newVideo: '',
      newFile: '',
      materialModel: {
        nama: '',
        subTopik: '',
        brief: '',
        videos: '',
      },
    }
  },
  props: ['session', 'materials', 'videoUrls', 'fileMaterials', 'isEdit'],
  methods: {
    onChange() {
      this.$emit('updatedMaterial', this.materialModel)
    },
    addVideo() {
      if (this.materialModel.videos && this.materialModel.videos.length === 5) {
        this.$notification.error({
          message: 'Sorry.',
          description: 'Maximum videos that can be uploaded is 5.',
        })
      } else {
        const checkUrl = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
        let newVideo = this.newVideo
        if (newVideo.match(checkUrl)) {
          if (newVideo.match(/(?=.*youtube)(?=.*v=)/gi)) {
            newVideo = newVideo.split('v=')[1]
            newVideo = `https://www.youtube.com/embed/${newVideo}`
          } else if (newVideo.match(/youtu.be/gi)) {
            newVideo = newVideo.split('.be/')[1]
            newVideo = `https://www.youtube.com/embed/${newVideo}`
          }
          this.loadingAddVideo = true
          setTimeout(() => {
            this.materialModel.videos.push(newVideo)
            this.$emit('updatedMaterial', this.materialModel)
            this.loadingAddVideo = false
            this.$notification.success({
              message: 'Video Added.',
            })
            this.newVideo = ''
          }, 2000)
        } else {
          this.$notification.error({
            message: 'Sorry.',
            description: 'Your input is not a link. Please add proper youtube link.',
          })
        }
      }
    },
    deleteVideo(id) {
      this.materialModel.videos.splice(id, 1)
      this.$emit('updatedMaterial', this.materialModel)
    },
    downloadFile(path, filename) {
      const href = `${config.apiUrl}/${path}${filename}`
      // console.log(href)
      window.open(href, '_blank')
    },
    deleteFile(id, filepath, filename) {
      this.loadingDelete = true
      this.$confirm({
        title: 'Delete File',
        content: (
          <div>Are you sure to delete {filename}?</div>
        ),
        onOk: () => {
          this.$store.dispatch('slot/DELETE_FILE_MATERIAL', {
            id: id,
            id_topik: this.materials.id,
          })
          this.loadingDelete = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Delete',
      })
    },
    async addFile() {
      this.loadingAdd = true
      const formData = new FormData()
      const tingkat = this.session.slot.kela.tingkat
      const mapel = this.session.slot.mata_pelajaran.nama
      const filepath = `Topics/${mapel}/${tingkat}/`
      formData.append('id_topik', this.materials.id)
      formData.append('filepath', filepath)
      formData.append('fileUploadMaterial', this.newFile)
      try {
        // VUEX GABISA LEMPAR FILE.. MANGKANYA ADA AXIOS DISINI.. TRUST ME ITS WORKS
        await axios.post(`${config.apiUrl}/api/file/file-topik`, formData, {
          headers: {
            token: localStorage.token,
          },
        })
        this.$store.dispatch('slot/FETCH_FILE_MATERIAL', { idTopik: this.materials.id })
        this.newFile = ''
        this.loadingAdd = false
      } catch (error) {
        this.loadingAdd = false
        console.log(error)
      }
    },
    selectFile() {
      const file = this.$refs.file.$refs.input.files[0]
      // console.log(config.uploader.MAX_SIZE / 1000)
      // const video = 'video/quicktime'
      const word = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      // const xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      const pdf = 'application/pdf'
      // const jpg = 'image/jpeg'
      // const png = 'image/png'
      const allowedTypes = [word, pdf]
      const tooLarge = file.size > config.uploader.MAX_SIZE
      if (allowedTypes.includes(file.type) && !tooLarge) {
        // this.errorFile.status = false
        // this.errorFile.message = ''
        this.newFile = file
      } else {
        this.newFile = null
        this.$notification.error({
          message: 'Error',
          description: tooLarge ? `Your file too large. Max size is ${config.uploader.MAX_SIZE / 1000}Kb` : 'Only (DOCX, PDF) are allowed',
        })
      }
      // this.newFile = this.$refs.file.$refs.input.files[0]
      // console.log(this.newFile)
    },
  },
  created() {
    this.materialModel.nama = this.materials.nama
    this.materialModel.subTopik = this.materials.subtopik
    this.materialModel.brief = this.materials.brief || ''
    this.materialModel.videos = this.videoUrls
  },
  watch: {
    isEdit(newVal, oldVal) {
      // console.log(this.materials.brief)
      this.materialModel = {
        nama: this.materials.nama,
        subTopik: this.materials.subtopik,
        brief: this.materials.brief || '',
        videos: this.videoUrls,
      }
      this.$emit('updatedMaterial', this.materialModel)
      this.newVideo = ''
      this.newFile = ''
    },
  },
  computed: {
    editor() {
      // console.log(this.$store.state.ckEditor.editor)
      return this.$store.state.ckEditor.editor
    },
    editorConfig() {
      // console.log(this.$store.state.ckEditor.editorConfig)
      return this.$store.state.ckEditor.editorConfig
    },
  },
}
</script>
<style lang="scss">
.editMaterial {
  .content {
    font-family: "Mukta", "sans-serif";
    font-weight: 500;
    font-size: 16px;
    margin: auto;
    width: 65%;
    @media (max-width: 769px) {
      width: 100%;
    }
    .ck-editor__editable {
      min-height: 50vh;
    }
  }
  .videoThumbnail::after {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }
}
</style>
